import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm';
import Modal from 'tg-modal/es';
import { Button } from 'react-bootstrap/es';
import { useDJConst } from './DJConstContext';
import { gettext } from '../utils/text';

export function AutoLogout() {
    const {
        auto_logout: {
            AUTO_LOGOUT_TIMEOUT: timeout,
            AUTO_LOGOUT_PROMPT_TIMEOUT: promptTimeout,
            AUTO_LOGOUT_ENABLED: enabled,
        },
        user,
    } = useDJConst();

    const [remaining, setRemaining] = useState<number>(timeout + promptTimeout);
    const [open, setOpen] = useState<boolean>(false);

    const onIdle = () => {
        setOpen(false);
        window.location.href = `/logout/?next=${window.location.pathname}`
    };

    const onActive = () => {
        setOpen(false);
    };

    const onPrompt = () => {
        setOpen(true);
    };
    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptTimeout,
        throttle: 500,
        crossTab: true,
        syncTimers: 200,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    if (!enabled) {
        return null;
    }

    const timeTillPrompt = Math.max(remaining - promptTimeout / 1000, 0);
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

    return (
        <>
            {user.debug_mode_enabled && (
                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 2000,
                    background: 'rgba(255,0,0,0.45)',
                    color: 'white',
                    padding: '5px',
                    borderRadius: '0 10px 0 0',
                }}>
                    {timeTillPrompt > 0 && (
                        <span>
                            {timeTillPrompt} {seconds} until auto-logout prompt
                        </span>
                    )}
                </div>
            )}
            {open && 
            <Modal
                isOpen={true}
                dialogClassName="tg-modal-dialog auto-logout-modal"
                onCancel={activate}
            >
                <Modal.Header addClose={false}>
                    {gettext('Are you still there?')}
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {gettext('Logging out in:')} {remaining}
                    </p>
                </Modal.Body>
                <div className="tg-modal-footer">
                    <Button
                        bsStyle="success"
                        className="btn btn-success btn-fullwidth"
                        onClick={() => {
                            activate();
                            setOpen(false);
                        }}
                    >
                        {gettext("Yes, I'm still here")}
                    </Button>
                </div>
            </Modal>
            }
        </>
    );
}
