import loadable from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import moment from 'moment';
import { Provider } from 'react-redux';

import createReduxStore from 'core/store';
import api from 'core/utils/api';
import { DJConstProvider } from 'core/components/DJConstContext';
import rootSaga from 'core/sagas';
import { VIEW_TYPE_PARTS } from 'accounts/constants';
import { AutoLogout } from './core/components/AutoLogout';

const PipesOrdersPage = loadable(() => import('pages/pipes/PipesOrdersPage'));

const PipesTimeplanPage = loadable(() =>
    import('pages/pipes/PipesTimeplanPage'),
);
const PipesOperationsPage = loadable(() =>
    import('pages/pipes/PipesOperationsPage'),
);
const PipesCoolingPage = loadable(() => import('pages/pipes/PipesCoolingPage'));
const PipesQrsPage = loadable(() => import('pages/pipes/PipesQrsPage'));
const PipesQrsTimelinePage = loadable(() =>
    import('pages/pipes/PipesQrsTimelinePage'),
);
const PipesReportsPage = loadable(() => import('pages/pipes/PipesReportsPage'));

const PartsOrdersPage = loadable(() => import('pages/parts/PartsOrdersPage'));
const PartsSalesToolPage = loadable(() =>
    import('pages/parts/PartsSalesToolPage'),
);
const PartsTimeplanPage = loadable(() =>
    import('pages/parts/PartsTimeplanPage'),
);
const PartsOperationsPage = loadable(() =>
    import('pages/parts/PartsOperationsPage'),
);
const PartsTemplatesPage = loadable(() =>
    import('pages/parts/PartsTemplatesPage'),
);
const ReactElements = loadable(() => import('core/components/ReactElements'));

const DEBUG = process.env.NODE_ENV !== 'production';

// Install Raven in production envs
if (!DEBUG) {
    Sentry.init({
        dsn: DJ_CONST.SENTRY_DSN,
        release: DJ_CONST.SENTRY_RELEASE,
        environment: `${DJ_CONST.SENTRY_ENVIRONMENT}-JS`,
        integrations: [new Integrations.BrowserTracing()],
        normalizeDepth: 10,
    });
    // If we have authenticated user, pass its data on to Raven
    if (DJ_CONST.user) {
        Sentry.setUser({
            id: DJ_CONST.user.id,
            email: DJ_CONST.user.email,
            name: DJ_CONST.user.name,
        });
    }
}

moment.locale('et');

/* eslint-disable quote-props */
/*
    routeMap expects:
    key (Django urlconf name e.g. qa:home): {
        key (standard CSS-syntax, will render to all matching elements): Module imported via loadable.
    }

    > Note: To provide a reducer for the module please export it under the name `reducer` from
    >  the lazily imported component file.
 */
const pipeRouteMap = {
    'qa:home': {
        '#react-container': PipesQrsPage,
    },
    'qa:timeline': {
        '#react-container': PipesQrsTimelinePage,
    },
    'cooling:home': {
        '#react-container': PipesCoolingPage,
    },
    'orders:home': {
        '#react-container': PipesOrdersPage,
    },
    'operations:home': {
        '#operations-container': PipesOperationsPage,
    },
    'prod:timeplan': {
        '#timeplan-container': PipesTimeplanPage,
    },
    'reports:home': {
        '#reports-container': PipesReportsPage,
    },
    react_elements: {
        '#react-container': ReactElements,
    },
};
const partRouteMap = {
    'orders:home': {
        '#react-container': PartsOrdersPage,
    },
    'orders:sales-tool': {
        '#react-container': PartsSalesToolPage,
    },
    'prod:timeplan': {
        '#timeplan-container': PartsTimeplanPage,
    },
    'operations:home': {
        '#operations-container': PartsOperationsPage,
    },
    'configurations:part-templates': {
        '#react-container': PartsTemplatesPage,
    },
};
/* eslint-enable quote-props */

/**
 * Do manual initialization of global elements.
 *
 * Currently used to avoid including the whole of JQuery for some bootstrap elements.
 */
function initGlobalJs() {
    /** @param {Element} el */
    const toggleOpen = (el, key) => {
        if (el.classList.contains(key)) {
            el.classList.remove(key);
        } else {
            el.classList.add(key);
        }
    };

    /** @type {HTMLCollectionOf<Element>} */
    const dropDowns = document.getElementsByClassName('dropdown');
    for (let i = 0; i < dropDowns.length; i++) {
        const el = dropDowns[i];
        el.onclick = () => toggleOpen(el, 'open');
    }

    /** @type {HTMLCollectionOf<Element>} */
    const collapseElements = document.getElementsByClassName('collapse');
    for (let i = 0; i < collapseElements.length; i++) {
        const el = collapseElements[i];
        document
            .querySelectorAll(`[data-target="#${el.id}"]`)
            .forEach((trigger) => {
                trigger.onclick = () => toggleOpen(el, 'show'); // eslint-disable-line
            });
    }
}

function initNavbar() {
    if (DJ_CONST.config.VIEW_SWITCH_ACTIVE) {
        /**
         * @param {Event} e
         */
        const setOpenView = (e) => {
            /** @type {NamedNodeMap} */
            const nnm = e.target.attributes;
            const toOpen = nnm.getNamedItem('data-to-open').value;
            api.openView
                .post(null, { to_open: toOpen })
                .then(() => window.location.replace('/'));
        };

        /** @type {HTMLCollectionOf<Element>} */
        const openGlobalViewChoices = document.getElementsByClassName(
            'open-global-view',
        );
        for (let i = 0; i < openGlobalViewChoices.length; i++) {
            openGlobalViewChoices[i].onclick = setOpenView;
        }
    }
}

/**
 * @param {String} routeName
 */
function init(routeName) {
    initNavbar();
    initGlobalJs();

    const routeMap =
        DJ_CONST.open_view === VIEW_TYPE_PARTS ? partRouteMap : pipeRouteMap;
    const configs = routeMap[routeName];

    if (!configs) {
        return [];
    }

    const stores = {};

    Object.keys(configs).forEach((selector) => {
        const loadableComponent = configs[selector];
        const matches = document.querySelectorAll(selector);

        // people don't like this hack because other people are dumb
        // don't be dumb - learn javascript before complaining about it on the internet
        Array.prototype.forEach.call(matches, (elem) => {
            loadableComponent.load().then((exported) => {
                const Component = exported.default;
                const { reducer } = exported;

                if (!reducer) {
                    ReactDOM.render(<Component />, elem);
                    return;
                }

                const { store, sagaMiddleware } = createReduxStore(reducer);
                sagaMiddleware.run(rootSaga);

                stores[`${routeName}-${selector}`] = store;

                ReactDOM.render(
                    <Provider store={store}>
                        <DJConstProvider djConst={DJ_CONST}>
                            <Component />
                            <AutoLogout />
                        </DJConstProvider>
                    </Provider>,
                    elem,
                );
            });
        });
    });

    return stores;
}

export { init };
